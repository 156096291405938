export const config = {
    guiOptions: {
      consentModal: {
          layout: "bar inline",
          position: "bottom",
          equalWeightButtons: true,
          flipButtons: false
      },
      preferencesModal: {
          layout: "box",
          position: "right",
          equalWeightButtons: true,
          flipButtons: false
      }
    },
    categories: {
        necessary: {
            readOnly: true
        },
        analytics: {}
    },
    language: {
        default: "es",
        autoDetect: "browser",
        translations: {
            es: {
                consentModal: {
                    title: "Uso de cookies",
                    description: "Nos gustaría utilizar cookies opcionales para entender mejor su uso de este sitio web, mejorar nuestro sitio web y compartir información con nuestros socios publicitarios. Su consentimiento también cubre la transferencia de sus Datos Personales a terceros países no seguros, especialmente a los Estados Unidos, lo que implica el riesgo de que las autoridades públicas puedan acceder a sus Datos Personales para vigilancia y otros fines sin medidas efectivas. Puede encontrar información detallada sobre el uso de cookies estrictamente necesarias, que son esenciales para navegar en este sitio web, y cookies opcionales, que puede rechazar o aceptar a continuación, y sobre cómo puede cambiar o revocar su consentimiento en cualquier momento en nuestra <a target=\"_blank\" href=\"/aviso_de_privacidad.pdf\">Política de privacidad</a>",
                    acceptAllBtn: "Aceptar todo",
                    acceptNecessaryBtn: "Rechazar todo",
                    showPreferencesBtn: "Gestionar preferencias",
                    footer: "<a target=\"_blank\" href=\"/aviso_de_privacidad.pdf\">Política de privacidad</a>\n<a target=\"_blank\" href=\"/muestratumarca_terminos_y_condiciones.pdf\">Términos y condiciones</a>"
                },
                preferencesModal: {
                    title: "Preferencias de Consentimiento",
                    acceptAllBtn: "Aceptar todo",
                    acceptNecessaryBtn: "Rechazar todo",
                    savePreferencesBtn: "Guardar preferencias",
                    closeIconLabel: "Cerrar modal",
                    serviceCounterLabel: "Servicios",
                    sections: [
                        {
                            title: "Su privacidad",
                            description: "Cuando visita un sitio web, este puede almacenar o recuperar información en su navegador, principalmente en forma de cookies. Esta información puede ser sobre usted, sus preferencias o su dispositivo y se utiliza principalmente para lograr que el sitio funcione como se espera. La información generalmente no lo identifica en forma directa, pero puede brindarle una experiencia web más personalizada. Como respetamos su privacidad, puede optar por excluir algunos tipos de cookies. Puede hacer clic en los diferentes encabezados de categoría para obtener más información y cambiar nuestra configuración predeterminada. Sin embargo, si bloquea algunos tipos de cookies, su experiencia de uso en el sitio se puede ver afectada y también los servicios que podemos ofrecerle. <a target=\"_blank\" href=\"/aviso_de_privacidad.pdf\">Política de privacidad</a>"
                        },
                        {
                            title: "Cookies Estrictamente Necesarias <span class=\"pm__badge\">Siempre Habilitado</span>",
                            description: "Estas cookies son necesarias para que el sitio web funcione y no se pueden desactivar en nuestros sistemas. Usualmente están configuradas para responder a acciones hechas por usted para recibir servicios, tales como ajustar sus preferencias de privacidad, iniciar sesión en el sitio, o llenar formularios. Usted puede configurar su navegador para bloquear o alertar la presencia de estas cookies, pero algunas partes del sitio web no funcionarán. Estas cookies no guardan ninguna información personal identificable.",
                            linkedCategory: "necessary"
                        }
                    ]
                }
            }
        }
    },
    disablePageInteraction: true
};