import React from "react";

function Elipsis() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="149"
      height="74"
      fill="none"
      viewBox="0 0 149 74"
    >
      <path
        stroke="#DBFF6E"
        strokeWidth="6"
        d="M146 37c0 8.458-6.984 16.935-20.155 23.476C112.86 66.926 94.711 71 74.5 71c-20.21 0-38.36-4.075-51.345-10.524C9.985 53.935 3 45.458 3 37c0-8.458 6.984-16.935 20.155-23.476C36.14 7.074 54.289 3 74.5 3c20.21 0 38.36 4.075 51.345 10.524C139.016 20.065 146 28.542 146 37z"
      ></path>
    </svg>
  );
}

export default Elipsis;