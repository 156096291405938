import React from "react";

function Cloud({ className }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 1179 1156"
      className={className}
    >
      <path
        fill="url(#paint0_linear_2280_11933)"
        d="M333.712 141.032c45.37-151.329 374.313-186.393 734.738-78.253 38.1 11.427 75.03 23.997 110.55 37.518V1120.86c-85.13 34.72-218.946 40.83-369.137 29.66-253.119-18.83-488.749-133.82-477.192-247.759 4.67-46.033 42.426-85.155 102.403-113.736C128.814 674.9-34.785 555.921 6.23 419.031c28.332-94.557 167.36-143.705 355.607-140.923-29.986-47.954-40.786-94.818-28.125-137.076z"
      ></path>
      <defs>
        <linearGradient
          id="paint0_linear_2280_11933"
          x1="575.247"
          x2="575.247"
          y1="0.288"
          y2="1152.25"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#87C1FA"></stop>
          <stop offset="0.289" stopColor="#8FC6FC"></stop>
          <stop offset="0.39" stopColor="#AED6FD"></stop>
          <stop offset="0.515" stopColor="#C8E3FE"></stop>
          <stop offset="0.584" stopColor="#D7EBFE"></stop>
          <stop offset="0.789" stopColor="#F9FCFF"></stop>
          <stop offset="1" stopColor="#fff"></stop>
        </linearGradient>
      </defs>
    </svg>
  );
}

export default Cloud;
