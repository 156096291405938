import React from "react";
import IMG from "../../assets/muestratumarca-white.gif";

function MuestraTuMarcaWhite({ className }) {
  return (
      <img
        src={IMG}
        alt="Muestra tu Marca" 
      />
  );
}

export default MuestraTuMarcaWhite;
