export const defaultValue = {
  left: {
    img: "",
    rotation: "0",
  },
  right: {
    img: "",
    rotation: "0",
  },
  top: {
    img: "",
    rotation: "0",
  },
};

export function rotateIcon(radian) {
  const degrees = parseInt(radian, 10);
  const newDegrees = degrees - 45;
  const newRadian = newDegrees.toString();
  return newRadian;
}

export function rotateSvg(svgData, degrees) {
  // Aplica la transformación de rotación al SVG
  //const rotatedSvg = svgData.replace('<svg', `<svg><g transform="rotate(${degrees} ${centerX} ${centerY})">`).replace('</svg>', '</g></svg>');
  const rotatedSvg = svgData.replace('<svg', `<svg transform="rotate(${degrees})"`);
  console.log(degrees);
  return rotatedSvg;
}

