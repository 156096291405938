import React from "react";
import SVG from "../../assets/arrow-l.svg";

function ArrowL() {
  return (
    <img
    src={SVG}
    alt="Anterior"
  />
  );
}

export default ArrowL;