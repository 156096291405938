import { useState, useEffect } from "react";
//import VideoPlayer from "./components/video";
import CanvasPlayer from "./components/canvas";
import Presentation from "./views/presentation/presentation";
import Description from "./views/description/description";
import Customization from "./views/customization/customization";
import "vanilla-cookieconsent/dist/cookieconsent.css";
import * as CookieConsent from "vanilla-cookieconsent";
import { config } from "./components/cookie/config";

import "./App.css";
import {
  MobileCustomization,
  MobileDescription,
  MobilePresentation,
} from "./mobile";

function App() {
  const [activateVideo, setActivateVideo] = useState(false);
  const [isMobile, setIsMobile] = useState(false);

 

  useEffect(() => {
    localStorage.clear()
    const userAgent = navigator.userAgent.toLowerCase();
    const isMobileDevice = userAgent.includes("mobile");
    setIsMobile(isMobileDevice);
    CookieConsent.run(config);
  }, []);

  if (isMobile) {
    return (
      <>
      {!activateVideo ? (
        <div className="App">
          <MobilePresentation />
          <MobileDescription/>
          <MobileCustomization setActivateVideo={setActivateVideo}/>
        </div>
      ) : (
        <CanvasPlayer setActivateVideo={setActivateVideo}/>
      )}
    </>
    )
  }

  return (
    <>
      {!activateVideo ? (
        <div className="App">
          <Presentation />
          <Description />
          <Customization setActivateVideo={setActivateVideo} />
        </div>
      ) : (
        <CanvasPlayer setActivateVideo={setActivateVideo}/>
      )}
    </>
  );
}

export default App;
