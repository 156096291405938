import React from "react";
import IMG from "../../assets/luna.png";

function LunaLogo({width}) {
  return (
    <img
        src={IMG}
        alt="Luna" 
      />
  );
}

export default LunaLogo;