import React from "react";
import { Link } from "react-scroll";
import SVG from "../../assets/Arrow.svg";

function Arrow({ className }) {
  return (
      <Link 
        to="description"
        smooth={true}
        duration={500}
        spy={true}
        offset={-70}
      >
        <img
          src={SVG}
          alt="Desplazar a la siguiente sección"
        />
      </Link>
  ); 
}

export default Arrow;

