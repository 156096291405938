import React, { useEffect } from "react";
import styles from "./magicmodal.module.css";
import Gif from "../../assets/loader.svg";

function MagicModal({ onLoadVideo }) {
  useEffect(() => {
    const timeout = setTimeout(() => onLoadVideo(), 2000);
    return () => {
      clearTimeout(timeout);
    };
  }, [onLoadVideo]);

  return (
    <div className={styles.container}>
      <div className={styles.containerText}>
        <p className={styles.text}>Espera para ver la magia</p>
        <img src={Gif} alt=""/>
      </div>
    </div>
  );
}

export default MagicModal;
