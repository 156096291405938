import { CloudBackground, Luna } from "../../components/branding";
import BepanthenxLunaWhite from "../../components/branding/BepanthenxLunaWhite";
import MuestraTuMarcaWhite from "../../components/branding/MuestraTuMarcaWhite";
import BepanthenxLunaBlue from "../../components/branding/BepanthenxLunaBlue";
import MuestraTuMarcaBlue from "../../components/branding/MuestraTuMarcaBlue";
import Arrow from "../../components/icons/arrow";
import styles from "./presentation.module.css";

function Presentation() {
  return (
    <div className={styles.container}>
      <div className={styles.desktop}>
        <div className={styles.header}>
          <div className={styles.luna}>
            <Luna />
          </div>
          <div className={styles.logos}>
            <BepanthenxLunaBlue />
            <MuestraTuMarcaBlue />
            <div className={styles.arrow}>
              <Arrow />
            </div>
          </div>
          <div className={styles.cloudbg}>
            <CloudBackground className={styles.cloudBackground} />
          </div>
          <div className={styles.gradient}></div>
        </div>
      </div>
      <div className={styles.mobile}>
        <div className={styles.header}>
          <div className={styles.logos}>
            <BepanthenxLunaWhite />
            <MuestraTuMarcaWhite />
          </div>
          <div className={styles.luna}>
            <Luna />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Presentation;
