import React, { useEffect, useState } from "react";
import { EnumMarks, GridEnumMarks } from "../../assets/marks";
import styles from "./tools.module.css";
import Miniature from "../miniature";
import Grid from "../customize-grid/grid";
import { defaultValue, rotateIcon, rotateSvg } from "../utils";

function DesktopTools() {
  const [marks, setMarks] = useState(defaultValue);
  const [canShow, setCanShow] = useState(false);
  const [selectedItem, setSelectedItem] = useState();

  const handleRotate = (id) => {
    setMarks({
      ...marks,
      [id]: {
        img: marks[id].img,
        rotation: rotateIcon(marks[id].rotation),
        isSelected: marks[id].isSelected,
      },
    });
  };

  const handleSelect = (event) => {
    if (
      event?.target?.id === "left" ||
      event?.target?.id === "right" ||
      event?.target?.id === "top" ||
      event?.target?.id === "toolkit"
    ) {
      if (event.target.id !== "toolkit") {
        setMarks({
          left: {
            img: marks.left.img,
            rotation: marks.left.rotation,
            isSelected: event.target.id === "left" ? true : false,
          },
          top: {
            img:  marks.top.img,
            rotation: marks.top.rotation,
            isSelected: event.target.id === "top" ? true : false,
          },
          right: {
            img:  marks.right.img,
            rotation: marks.right.rotation,
            isSelected: event.target.id === "right" ? true : false,
          },
        });
      }
    } else {
      setMarks({
        left: {
          img: marks.left.img,
          rotation: marks.left.rotation,
          isSelected: false,
        },
        right: {
          img: marks.right.img,
          rotation: marks.right.rotation,
          isSelected: false,
        },
        top: {
          img: marks.top.img,
          rotation: marks.top.rotation,
          isSelected: false,
        },
      });
    }
  };

  const handleDelete = (id) => {
    setMarks({
      ...marks,
      [id]: {
        img: "",
        rotation: "0",
        isSelected: false,
      },
    });
    if (id === "left") localStorage.removeItem("leftMark");
    if (id === "right") localStorage.removeItem("rightMark");
    if (id === "top") localStorage.removeItem("frontMark");
  };

  const handleDragStart = (string) => {
    setCanShow(true);
    setSelectedItem(string);
  };

  const handleDragEnd = () => {
    setCanShow(false);
    setSelectedItem();
  };

  const onDragOver = (id) => {
    if (selectedItem) {
      setMarks({
        left: {
          img: id === "left" ? selectedItem: marks.left.img,
          rotation: marks.left.rotation,
          isSelected: id === "left" ? true : false,
        },
        top: {
          img: id === "top" ? selectedItem: marks.top.img,
          rotation: marks.top.rotation,
          isSelected: id === "top" ? true : false,
        },
        right: {
          img: id === "right" ? selectedItem: marks.right.img,
          rotation: marks.right.rotation,
          isSelected: id === "right" ? true : false,
        },
      });
    }
  };

  useEffect(() => {
    if (marks.left.img !== "") {
      fetch(GridEnumMarks[marks.left.img]?.image)
        .then((response) => response.text())
        .then((svgData) => {
          const svgBlob = new Blob([rotateSvg(svgData, marks.left.rotation)], {
            type: "image/svg+xml",
          });
          const url = URL.createObjectURL(svgBlob);
          localStorage.setItem("leftMark", url);
        });
    }
    if (marks.top.img !== "") {
      fetch(GridEnumMarks[marks.top.img]?.image)
        .then((response) => response.text())
        .then((svgData) => {
          const svgBlob = new Blob([rotateSvg(svgData, marks.top.rotation)], {
            type: "image/svg+xml",
          });
          const url = URL.createObjectURL(svgBlob);
          localStorage.setItem("frontMark", url);
        });
    }
    if (marks.right.img !== "") {
      fetch(GridEnumMarks[marks.right.img]?.image)
        .then((response) => response.text())
        .then((svgData) => {
          const svgBlob = new Blob([rotateSvg(svgData, marks.right.rotation)], {
            type: "image/svg+xml",
          });
          const url = URL.createObjectURL(svgBlob);
          localStorage.setItem("rightMark", url);
        });
    }
  }, [marks]);

  return (
    <div
      className={styles.tool}
      onDragOver={(event) => event.preventDefault()}
      onClick={(event) => handleSelect(event)}
    >
      <div className={styles.face}>
        <Grid
          marks={marks}
          handleRotate={handleRotate}
          canShow={canShow}
          onDragOver={onDragOver}
          handleDelete={handleDelete}
          handleSelect={handleSelect}
        />
      </div>
      <div className={styles.marks}>
        {EnumMarks.map((item) => (
          <Miniature
            image={item.image}
            key={item.name}
            name={item.name}
            handleDragStart={handleDragStart}
            handleDragEnd={handleDragEnd}
          />
        ))}
      </div>
    </div>
  );
}

export default DesktopTools;
