import React from "react";
import SVG from "../../assets/arrow-r.svg";

function ArrowR() {
  return (
    <img
    src={SVG}
    alt="Siguiente"
  />
  );
}

export default ArrowR;