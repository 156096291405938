import React from "react";
import styles from "./description.module.css";

function Description() {
  return (
    <div className={styles.container} id="description">
      <div className={styles.bg}></div>
      <p className={styles.textOne}>
      Amar tu piel, es el primer paso para sanarla. Únete a Bepanthen® y El Mundo de Luna para reescribir la historia de las condiciones de <span className={styles.skin}>la piel</span>.
      </p>
      <p className={styles.textTwo}>
      Utiliza las herramientas para ver tus propias marcas en la piel de Luna.
      </p>
    </div>
  );
}

export default Description;
