import mark1 from "../marks/1.svg";
import mark2 from "../marks/2.svg";
import mark3 from "../marks/3.svg";
import mark4 from "../marks/4.svg";
import mark5 from "../marks/5.svg";
import mark6 from "../marks/6.svg";
import mark7 from "../marks/7.svg";
import mark8 from "../marks/8.svg";
import mark9 from "../marks/9.svg";
import mark10 from "../marks/10.svg";
import mark11 from "../marks/11.svg";
import mark12 from "../marks/12.svg";
import mark13 from "../marks/13.svg";
import mark14 from "../marks/14.svg";
import mark15 from "../marks/15.svg";
import mark16 from "../marks/16.svg";


export const EnumMarks = [
  { name: "mark1", id: 0, image: mark1 },
  { name: "mark2", id: 1, image: mark2 },
  { name: "mark3", id: 2, image: mark3 },
  { name: "mark4", id: 3, image: mark4 },
  { name: "mark5", id: 4, image: mark5 },
  { name: "mark6", id: 5, image: mark6 },
  { name: "mark7", id: 6, image: mark7 },
  { name: "mark8", id: 7, image: mark8 },
  { name: "mark9", id: 8, image: mark9 },
  { name: "mark10", id: 9, image: mark10 },
  { name: "mark11", id: 10, image: mark11 },
  { name: "mark12", id: 11, image: mark12 },
  { name: "mark13", id: 12, image: mark13 },
  { name: "mark14", id: 13, image: mark14 },
  { name: "mark15", id: 14, image: mark15 },
  { name: "mark16", id: 15, image: mark16 },
];

export const GridEnumMarks = {
  mark1: { id: 0, image: mark1 },
  mark2: { id: 1, image: mark2 },
  mark3: { id: 2, image: mark3 },
  mark4: { id: 3, image: mark4 },
  mark5: { id: 4, image: mark5 },
  mark6: { id: 5, image: mark6 },
  mark7: { id: 6, image: mark7 },
  mark8: { id: 7, image: mark8 },
  mark9: { id: 8, image: mark9 },
  mark10: { id: 9, image: mark10 },
  mark11: { id: 10, image: mark11 },
  mark12: { id: 11, image: mark12 },
  mark13: { id: 12, image: mark13 },
  mark14: { id: 13, image: mark14 },
  mark15: { id: 14, image: mark15 },
  mark16: { id: 15, image: mark16 },
};