import React, { useState, useEffect } from "react";
import styles from "./miniature.module.css";

function Miniature({
  image,
  name,
  handleDragStart,
  handleTouchStartItem,
  handleDragEnd,
}) {
  const [isDragging, setIsDragging] = useState(false);
  const [startPosition, setStartPosition] = useState({ x: 0, y: 0 });
  const [offset, setOffset] = useState({ x: 0, y: 0 });

  useEffect(() => {
    const handleBodyTouchMove = (event) => {
      if (isDragging) {
        event.preventDefault();
      }
    };

    document.body.addEventListener("touchmove", handleBodyTouchMove, {
      passive: false,
    });

    return () => {
      document.body.removeEventListener("touchmove", handleBodyTouchMove);
    };
  }, [isDragging]);

  const handleTouchStart = (event) => {
    const touch = event.touches[0];
    setStartPosition({ x: touch.clientX, y: touch.clientY });
    handleTouchStartItem(name);
    setIsDragging(true);
  };

  const handleTouchMove = (event) => {
    if (!isDragging) return;
    const touch = event.touches[0];
    setOffset({
      x: touch.clientX - startPosition.x,
      y: touch.clientY - startPosition.y,
    });
  };

  const handleTouchEnd = () => {
    setIsDragging(false);
    setOffset({ x: 0, y: 0 });
  };

  return (
    <div
      className={styles.container}
      style={{
        transform: `translate(${offset.x}px, ${offset.y}px)`,
        cursor: isDragging ? "grabbing" : "grab",
        opacity: isDragging ? 0.5 : 1,
      }}
      onTouchMove={(event) => handleTouchMove(event)}
      onTouchEnd={handleTouchEnd}
    >
      <img
        src={image}
        className={styles.miniature}
        alt="Miniature"
        onDragStart={() => handleDragStart && handleDragStart(name)}
        onDragEnd={handleDragEnd}
        onTouchStart={handleTouchStart}
      />
    </div>
  );
}

export default Miniature;