import React, { useState } from "react";
import styles from "./gallery.module.css";
import Miniature from "../miniature";
import { ArrowLIcon, ArrowRIcon } from "../icons";

function Gallery({ itemsPerPage, data, handleTouchStartItem }) {
  const [currentPage, setCurrentPage] = useState(1);

  const totalPages = Math.ceil(data.length / itemsPerPage);

  const handlePrevPage = () => {
    if (currentPage > 1) {
      setCurrentPage((prevPage) => prevPage - 1);
    }
  };

  const handleNextPage = () => {
    if (currentPage < 4) {
      setCurrentPage((prevPage) => prevPage + 1);
    }
  };

  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = Math.min(startIndex + itemsPerPage, data.length);
  const currentItems = data.slice(startIndex, endIndex);

  return (
    <>
      <div className={styles.arrowContainer} onClick={handlePrevPage}>
        <ArrowLIcon />
      </div>
      <div className={styles.container}>
        <div className={styles.gallery}>
          {currentItems.map((item) => (
            <Miniature
              key={item.name}
              image={item.image}
              name={item.name}
              handleTouchStartItem={handleTouchStartItem}
            />
          ))}
        </div>
        <div className={styles.pagination}>
          {Array.from({ length: totalPages }, (_, index) => (
            <span
              key={index}
              className={index === currentPage - 1 ? styles.active : styles.dot}
              onClick={() => setCurrentPage(index + 1)}
            ></span>
          ))}
        </div>
      </div>
      <div className={styles.arrowContainer} onClick={handleNextPage}>
        <ArrowRIcon />
      </div>
    </>
  );
}

export default Gallery;
