import React from "react";
import IMG from "../../assets/bepanthenxlunablue.png";

function BepanthenxLunaBlue({ className }) {
  return (
      <img
        src={IMG}
        alt="Bepanthen x El mundo de Luna"
      />
  );
}

export default BepanthenxLunaBlue;
