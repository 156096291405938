import React, { useEffect, useState } from "react";
import styles from "./selector.module.css";
import DesktopTools from "../tools-desktop";
import TabletTools from "../tools-tablet";

function Selector() {
  const [isTouchable, setIsTouchable] = useState(false);

  function soportaEventosTactiles() {
    return (
      "ontouchstart" in window ||
      window.DocumentTouch ||
      navigator.maxTouchPoints > 0 ||
      navigator.msMaxTouchPoints > 0
    );
  }

  useEffect(() => {
    if (soportaEventosTactiles()) {
      setIsTouchable(true);
    } else {
      setIsTouchable(false);
    }
  }, []);

  return (
    <div className={styles.container}>
      <h2 className={styles.h2}>Personaliza a Luna</h2>
      {isTouchable ? <TabletTools /> : <DesktopTools />}
    </div>
  );
}

export default Selector;
