import React, { useEffect, useState } from "react";
import { EnumMarks, GridEnumMarks } from "../../assets/marks";
import styles from "./selector.module.css";
import Gallery from "../gallery";
import GridMobile from "../customize-grid-mobile";
import { defaultValue, rotateIcon, rotateSvg } from "../utils";

const SelectorMobile = () => {
  const [marks, setMarks] = useState(defaultValue);
  const [touchItem, setTouchItem] = useState(null);
  const [client, setClient] = useState();
  const [canShow, setCanShow] = useState(false);

  const handleTouchEnd = (event) => {
    setCanShow(false);
    setClient(event.changedTouches[0]);
    setTimeout(() => setTouchItem(null), 500);
    if (
      event?.target?.id === "left" ||
      event?.target?.id === "right" ||
      event?.target?.id === "top"
    ) {
      setMarks({
        right: {
          img: marks["right"].img,
          rotation: marks["right"].rotation,
          isSelected: false,
        },
        left: {
          img: marks["left"].img,
          rotation: marks["left"].rotation,
          isSelected: false,
        },
        top: {
          img: marks["top"].img,
          rotation: marks["top"].rotation,
          isSelected: false,
        },
      });
    }
  };

  const handleTouchStartItem = (string) => {
    setCanShow(true);
    setTouchItem(string);
  };

  const handleRotate = (id) => {
    setMarks({
      ...marks,
      [id]: {
        img: marks[id].img,
        rotation: rotateIcon(marks[id].rotation),
        isSelected: true,
      },
    });
  };

  const handleDelete = (id) => {
    setMarks({
      ...marks,
      [id]: {
        img: "",
        rotation: "0",
      },
    });
    if (id === "left") localStorage.removeItem("leftMark");
    if (id === "right") localStorage.removeItem("rightMark");
    if (id === "top") localStorage.removeItem("frontMark");
  };

  const handleSelect = (event) => {
    if (
      event?.target?.id === "left" ||
      event?.target?.id === "right" ||
      event?.target?.id === "top" ||
      event?.target?.id === "toolkit"
    ) {
      if (event.target.id !== "toolkit") {
        setMarks({
          left: {
            img: marks["left"].img,
            rotation: marks.left.rotation,
            isSelected: event.target.id === "left" ? true : false,
          },
          top: {
            img: marks["top"].img,
            rotation: marks.top.rotation,
            isSelected: event.target.id === "top" ? true : false,
          },
          right: {
            img: marks["right"].img,
            rotation: marks.right.rotation,
            isSelected: event.target.id === "right" ? true : false,
          },
        });
      }
    }
  };

  useEffect(() => {
    if (client) {
      const targetElement = document.elementFromPoint(
        client?.clientX,
        client?.clientY
      );
      if (
        touchItem &&
        (targetElement.id === "left" ||
          targetElement.id === "top" ||
          targetElement.id === "right")
      ) {
        setMarks({
          left: {
            img: targetElement.id === "left" ? touchItem : marks["left"].img,
            rotation: marks.left.rotation,
            isSelected: targetElement.id === "left" ? true : false,
          },
          top: {
            img: targetElement.id === "top" ? touchItem : marks["top"].img,
            rotation: marks.top.rotation,
            isSelected: targetElement.id === "top" ? true : false,
          },
          right: {
            img: targetElement.id === "right" ? touchItem : marks["right"].img,
            rotation: marks.right.rotation,
            isSelected: targetElement.id === "right" ? true : false,
          },
        });
      }
      if (!targetElement.id) {
        setMarks({
          left: {
            img: marks.left.img,
            rotation: marks.left.rotation,
            isSelected: false,
          },
          right: {
            img: marks.right.img,
            rotation: marks.right.rotation,
            isSelected: false,
          },
          top: {
            img: marks.top.img,
            rotation: marks.top.rotation,
            isSelected: false,
          },
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [client]);

  useEffect(() => {
    if (marks.left.img !== "") {
      fetch(GridEnumMarks[marks.left.img]?.image)
        .then((response) => response.text())
        .then((svgData) => {
          const svgBlob = new Blob([rotateSvg(svgData, marks.left.rotation)], {
            type: "image/svg+xml",
          });
          const url = URL.createObjectURL(svgBlob);
          localStorage.setItem("leftMark", url);
        });
    }
    if (marks.top.img !== "") {
      fetch(GridEnumMarks[marks.top.img]?.image)
        .then((response) => response.text())
        .then((svgData) => {
          const svgBlob = new Blob([rotateSvg(svgData, marks.top.rotation)], {
            type: "image/svg+xml",
          });
          const url = URL.createObjectURL(svgBlob);
          localStorage.setItem("frontMark", url);
        });
    }
    if (marks.right.img !== "") {
      fetch(GridEnumMarks[marks.right.img]?.image)
        .then((response) => response.text())
        .then((svgData) => {
          const svgBlob = new Blob([rotateSvg(svgData, marks.right.rotation)], {
            type: "image/svg+xml",
          });
          const url = URL.createObjectURL(svgBlob);
          localStorage.setItem("rightMark", url);
        });
    }
  }, [marks]);

  return (
    <div className={styles.container}>
      <h2 className={styles.h2}>Personaliza a Luna</h2>

      <div
        className={styles.tool}
        onTouchEnd={(event) => handleTouchEnd(event)}
      >
        <div className={styles.face}>
          <GridMobile
            marks={marks}
            handleRotate={handleRotate}
            canShow={canShow}
            handleDelete={handleDelete}
            handleSelect={handleSelect}
          />
        </div>
        <div className={styles.mobilemarks}>
          <Gallery
            itemsPerPage={4}
            data={EnumMarks}
            handleTouchStartItem={handleTouchStartItem}
          />
        </div>
      </div>
    </div>
  );
};

export default SelectorMobile;
