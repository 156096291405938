import React, { useRef } from "react";
import styles from "./canvas.module.css";
import BepanthenxLunaWhite from "../branding/BepanthenxLunaWhite";
import WhiteButtonLink from "../button/WhiteButtonLink";

function CanvasPlayer({ setActivateVideo }) {
  const iframeRef = useRef(null);

  const handleBack = () => {
    setActivateVideo(false);
    const smooth = () => {
      var element = document.getElementById("customization");
      element?.scrollIntoView({ behavior: "smooth", block: "start" });
    };
    setTimeout(smooth, 200);
  };

  return (
    <>
      <div className={styles.container}>
        <div className={styles.volverAtras} onClick={handleBack}>
          <a >Volver atrás</a>
        </div>

        <div className={styles.canvas}>
          {/* El iframe siempre está presente, pero se muestra basado en `showVideo` */}
          <iframe
            title="Luna Movie"
            ref={iframeRef}
            src="./iframe.html"
            scrolling="no"
            style={{ width: "100%", height: "100%", border: 0 }}
          ></iframe>

          {/* La imagen de vista previa y el botón de play solo se muestran si `showVideo` es false */}
        </div>
        <div className={styles.footer}>
          <BepanthenxLunaWhite className={styles.logo} />
          <div className={styles.conoceButton}>
            <WhiteButtonLink>Conoce más</WhiteButtonLink>
            <p>sobre Bepanthen®</p>
          </div>
        </div>

        <div className={styles.pieImprenta}>
          <p className={styles.marginP}>Cuida tu Piel. Aviso de publicidad 243300201B1251</p>
          <p className={styles.marginP}>Publicado por:</p>
          <p className={styles.marginP}>Bayer de México, S.A. de C.V.</p>
          <p>Blvd. Miguel de Cervantes Saavedra No. 259</p>
          <p>Col. Granada. México, D.F.</p>
          <p className={styles.marginP}>C.P. 11520</p>
          <p className={styles.marginP}>Tel. +52 (55) 5713 2001 (Conmutador)</p>
          <p>© Bayer de México, S.A. de C.V. Todos los derechos reservados.</p>
        </div>
      </div>
    </>
  );
}

export default CanvasPlayer;
