import React from "react";
import IMG from "../../assets/bepanthenxlunawhite.png";

function BepanthenxLunaWhite({ className }) {
  return (
      <img
        src={IMG}
        alt="Bepanthen x El mundo de Luna"
      />
  );
}

export default BepanthenxLunaWhite;
