import React from "react";
import styles from "./modal.module.css";
import OutLineButton from "../button/OutLineButton";
import BlueButton from "../button/BlueButton";

function Modal({ onAccept, onCancel }) {

  const openPDF = () => {
    window.open("/muestratumarca_terminos_y_condiciones.pdf", "_blank");
  };

  return (
    <div className={styles.container}>
      <div className={styles.content}>
        <div className={styles.modalContent}>
          <p className={styles.title}>
            Aceptar <a href="#" onClick={openPDF}>Términos y condiciones</a>
          </p>
          <div className={styles.buttonContainer}>
            <div className={styles.cancelButton}>
              <OutLineButton onClick={onCancel}>Cancelar</OutLineButton>
            </div>
              <div className={styles.acceptButton}>
                <BlueButton onClick={onAccept}>Aceptar</BlueButton>
              </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Modal;
