import React from "react";
import styles from "./grid.module.css";
import { GridEnumMarks } from "../../assets/marks";
import marktop from "../../assets/mark-top.svg";
import markleft from "../../assets/mark-left.svg";
import markright from "../../assets/mark-right.svg";

function GridMobile({
  marks,
  handleRotate,
  canShow,
  handleDelete,
  handleSelect,
}) {
  return (
    <div className={styles.container}>
        <div className={styles.markL} id="left">
          
          {marks?.left?.isSelected && (
            <div
              className={styles.toolKitLeft}
              onClick={(event) => handleSelect(event)}
            >
              <div
                className={styles.rotate}
                onClick={() => handleRotate("left")}
                aria-label="Rotar"
                id="toolkit"
              ></div>
              <div
                className={styles.delete}
                onClick={() => handleDelete("left")}
                aria-label="Eliminar"
                id="toolkit"
              ></div>
            </div>
          )}
          {canShow && marks.left.img === "" && (
            <img
              id="left"
              src={markleft}
              alt="Positions Marks Left"
              className={styles.dragLeft}
            />
          )}
          {marks.left.img !== "" && (
            <img
              id="left"
              src={GridEnumMarks[marks?.left?.img]?.image}
              alt="Positions Marks Left"
              style={{
                transform: `rotate(${marks?.left?.rotation}deg) scale(0.80)`,
              }}
              className={styles.markLeft}
              onClick={(event) => handleSelect(event)}
            />
          )}
        </div>

        <div className={styles.markT} id="top">
          {marks?.top?.isSelected && (
            <div
              className={styles.toolKitTop}
              onClick={(event) => handleSelect(event)}
            >
              <div
                className={styles.rotate}
                onClick={() => handleRotate("top")}
                aria-label="Rotar"
                id="toolkit"
              ></div>
              <div
                className={styles.delete}
                onClick={() => handleDelete("top")}
                aria-label="Eliminar"
                id="toolkit"
              ></div>
            </div>
          )}

          {canShow && marks.top.img === "" && (
            <img
              id="top"
              src={marktop}
              alt="Positions Marks Top"
              className={styles.dragTop}
            />
          )}
          {marks.top.img !== "" && (
            <img
              id="top"
              src={GridEnumMarks[marks?.top?.img]?.image}
              alt="Positions Marks Top"
              style={{
                transform: `rotate(${marks?.top?.rotation}deg) scale(0.80)`
              }}
              className={styles.markTop}
              onClick={(event) => handleSelect(event)}
            />
          )}
        </div>

        <div className={styles.markR} id="right">
          {marks?.right?.isSelected && (
            <div
              className={styles.toolKitRight}
              onClick={(event) => handleSelect(event)}
            >
              <div
                className={styles.rotate}
                onClick={() => handleRotate("right")}
                aria-label="Rotar"
                id="toolkit"
              ></div>
              <div
                className={styles.delete}
                onClick={() => handleDelete("right")}
                aria-label="Eliminar"
                id="toolkit"
              ></div>
            </div>
          )}
          {canShow && marks.right.img === "" && (
            <img
              id="right"
              src={markright}
              alt="Positions Marks right"
              className={styles.dragRight}
            />
          )}
          {marks.right.img !== "" && (
            <img
              id="right"
              src={GridEnumMarks[marks.right.img]?.image}
              alt="Positions Marks Right"
              style={{
                transform: `rotate(${marks?.right?.rotation}deg) scale(0.80)`
              }}
              className={styles.markRight}
              onClick={(event) => handleSelect(event)}
            />
          )}
        </div>
    </div>
  );
}

export default GridMobile;
