import React from "react";
import styles from "./button.module.css";

function WhiteButtonLink({ children, onClick }) {
  function handleClick() {
    window.open('https://www.bepanthen.com.mx', '_blank');
  }
  return (
    <button className={styles.whiteContainer} onClick={handleClick}>
      {children}
    </button>
  );
}
export default WhiteButtonLink;
