import React from "react";
import IMG from "../../assets/muestratumarca-blue.gif";

function MuestraTuMarcaBlue({ className }) {
  return (
      <img
        src={IMG}
        alt="Muestra tu Marca" 
      />
  );
}

export default MuestraTuMarcaBlue;
