import { useState } from "react";
import Button from "../../components/button";
import Modal from "../../components/modal";
import MagicModal from "../../components/modal/MagicModal";
import Selector from "../../components/selector";
import styles from "./customization.module.css";

function Customization({ setActivateVideo }) {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isMagicModalOpen, setIsMagicModalOpen] = useState(false);

  const handleRedirect = () => {
    setActivateVideo(true);
  };

  const handleAccept = () => {
    setIsMagicModalOpen(true);
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  return (
    <>
      <div className={styles.container} id='customization'>
          
          <Selector />
          <Button onClick={() => setIsModalOpen(true)}>¡Vamos!</Button>
      </div>

      {isModalOpen && <Modal onAccept={handleAccept} onCancel={handleCancel} />}
      {isMagicModalOpen && (
        <MagicModal onLoadVideo={handleRedirect} onCancel={handleCancel} />
      )}

      <div className={styles.pieImprenta}>
        <p className={styles.marginP}>Cuida tu Piel. Aviso de publicidad 243300201B1251</p>
        <p className={styles.marginP}>Publicado por:</p>
        <p className={styles.marginP}>Bayer de México, S.A. de C.V.</p>
        <p>Blvd. Miguel de Cervantes Saavedra No. 259</p>
        <p>Col. Granada. México, D.F.</p>
        <p className={styles.marginP}>C.P. 11520</p>
        <p className={styles.marginP}>Tel. +52 (55) 5713 2001 (Conmutador)</p>
        <p>© Bayer de México, S.A. de C.V. Todos los derechos reservados.</p>
      </div>

    </>    
  );
}

export default Customization;
