import React from "react";
import styles from "./grid.module.css";
import marktop from "../../assets/mark-top.svg";
import markleft from "../../assets/mark-left.svg";
import markright from "../../assets/mark-right.svg";
import { GridEnumMarks } from "../../assets/marks";

function Grid({
  marks,
  handleRotate,
  canShow = false,
  onDragOver,
  handleDelete,
  handleSelect,
}) {
  return (
    <div className={styles.container}>
      <div className={styles.markL} onDrop={() => onDragOver("left")}>
        {marks?.left?.isSelected && (
          <div
            className={styles.toolKitLeft}
            onClick={(event) => handleSelect(event)}
          >
            <div
              className={styles.rotate}
              onClick={() => handleRotate("left")}
              aria-label="Rotar"
              id="toolkit"
            ></div>
            <div
              className={styles.delete}
              onClick={() => handleDelete("left")}
              aria-label="Eliminar"
              id="toolkit"
            ></div>
          </div>
        )}
        {canShow && marks.left.img === "" && (
          <img
            id="left"
            src={markleft}
            alt="Positions Marks Left"
            className={styles.dragLeft}
          />
        )}
        {marks.left.img !== "" && (
          <img
            id="left"
            src={GridEnumMarks[marks?.left?.img]?.image}
            alt="Positions Marks Left"
            style={{
              transform: `rotate(${marks?.left?.rotation}deg)`,
            }}
            className={styles.markLeft}
            onDrop={() => onDragOver("left")}
            onClick={(event) => handleSelect(event)}
          />
        )}
      </div>

      <div className={styles.markT} onDrop={() => onDragOver("top")}>
          {marks?.top?.isSelected && (
            <div
              className={styles.toolKitTop}
              onClick={(event) => handleSelect(event)}
            >
              <div
                className={styles.rotate}
                onClick={() => handleRotate("top")}
                aria-label="Rotar"
                id="toolkit"
              ></div>
              <div
                className={styles.delete}
                onClick={() => handleDelete("top")}
                aria-label="Eliminar"
                id="toolkit"
              ></div>
            </div>
          )}
          {canShow && marks.top.img === "" && (
            <img
              id="top"
              src={marktop}
              alt="Positions Marks Top"
              className={styles.dragTop}
              style={{
                transform: `translateY(30%)`,
              }}
            />
          )}
          {marks.top.img !== "" && (
            <img
              id="top"
              src={GridEnumMarks[marks?.top?.img]?.image}
              alt="Positions Marks Top"
              style={{
                transform: `rotate(${marks?.top?.rotation}deg)`,
              }}
              className={styles.markTop}
              onDrop={() => onDragOver("top")}
              onClick={(event) => handleSelect(event)}
            />
          )}
      </div>

      <div className={styles.markR} onDrop={() => onDragOver("right")}>
        {marks?.right?.isSelected && (
          <div
            className={styles.toolKitRight}
            onClick={(event) => handleSelect(event)}
          >
            <div
              className={styles.rotate}
              onClick={() => handleRotate("right")}
              aria-label="Rotar"
              id="toolkit"
            ></div>
            <div
              className={styles.delete}
              onClick={() => handleDelete("right")}
              aria-label="Eliminar"
              id="toolkit"
            ></div>
          </div>
        )}
        {canShow && marks.right.img === "" && (
          <img
            id="right"
            src={markright}
            alt="Positions Marks right"
            className={styles.dragRight}
            onDrop={() => onDragOver("right")}
          />
        )}
        {marks.right.img !== "" && (
          <img
            id="right"
            src={GridEnumMarks[marks.right.img]?.image}
            alt="Positions Marks Right"
            style={{
              transform: `rotate(${marks?.right?.rotation}deg)`,
            }}
            className={styles.markRight}
            onClick={(event) => handleSelect(event)}
          />
        )}
      </div>

    </div>
  );
}

export default Grid;
